/* DatePicker.css */
.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-picker-container label {
  margin-right: 10px;
  color: #495057;
  font-weight: 500;
}

.date-picker-container input[type="date"] {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #495057;
}

.date-picker-container input[type="date"]:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.date-picker-container button {
  background-color: #5ca3ef;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 10px;
  margin: 0 5px;
}

.date-picker-container button:hover {
  background-color: #4376ae;
}

@media screen and (max-width: 480px) {
  .date-picker-container {
      flex-direction: column;
  }

  .date-picker-container label {
      margin: 0 0px 10px 0;
  }

  .date-picker-container input[type="date"],
  .date-picker-container button {
      margin: 5px 5px; 
  }
}
