.custom-border {
  border: 1px solid #ced4da; 
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  background-color: #f8f9fa;
  padding: 2px 10px 10px 10px; 
  margin-bottom: 2%;
}

.custom-border:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.card {
  overflow: hidden;
 
}
h3.category-title
{
  font-size:1.2rem;
}
