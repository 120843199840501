/* Event.css */
.card {
  transition: transform 0.2s; 
}

.card:hover {
  transform: scale(1.05); 
}
.text-center.category-title {
  font-size: 2rem;
  font-weight: 350;
  line-height: 1.2;
  text-decoration: semi-bold;
}
.category-title {
  font-size: 2rem;
  font-weight: 350;
  line-height: 1.2;
  text-decoration: semi-bold;
}
